import ClipLoader from "react-spinners/ClipLoader";
import Input from "../../../components/Input/Input";
import InputMasked from "../../../components/Input/InputMasked";
import Modal from "../../../components/Modals";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import requests from "../../../services/requests";
import Select from "react-select";
import { FaQuestionCircle } from "react-icons/fa";
import { Formik, ErrorMessage } from "formik";
import { toast } from "react-toastify";

const yup = require("yup");

const ModalRegister = ({ actionModal, tabModal, setTabModal, openModal, handleSuccess }) => {
    const [parentOptions, setParentOptions] = useState([]);

    useEffect(() => {
        if (openModal) {
            loadParent();
            ReactTooltip.rebuild();
        }
    }, [actionModal, loadParent]);

    const loadParent = async () => {
        requests
            .listClientsManage(
                {
                    isRootDocumentNumber: 1,
                },
                null,
                999999
            )
            .then(list => {
                let arr = [];
                list.data.map(client => {
                    return (
                        arr.push({
                            label: client.name + " (" + client.documentNumberFormatted + ")",
                            value: client.identifier,
                            documentNumber: client.documentNumber,
                        })
                    )
                });
                setParentOptions(arr);
            });
    };

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-5/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 onClick={() => setTabModal("Cadastro de cliente")} className={`mr-2 text-roxo_oficial font-bold`}>
                        Cadastro de cliente
                    </h5>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: "",
                        companyName: "",
                        documentNumber: "",
                        stateRegistrationNumber: "",
                        isParentCompany: "",
                        powerbiUrl: "",
                        conexosId: "",
                        city: "",
                        codeState: "",
                        parent: "",
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required("Campo obrigatório"),
                        companyName: yup.string(),
                        documentNumber: yup.string().required("Campo obrigatório"),
                        stateRegistrationNumber: yup.string(),
                        powerbiUrl: yup.string(),
                        conexosId: yup.string(),
                        city: yup.string().required("Campo obrigatório"),
                        codeState: yup.string().required("Campo obrigatório"),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);

                        requests
                            .addClient({ ...values, parent: values.parent.value, isParentCompany: values.isParentCompany.value })
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Cliente " + response.name + " cadastrado com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <ReactTooltip />
                            <div className="mx-5">
                                <div className="flex">
                                    <label htmlFor="name" className="text-roxo_oficial font-bold">
                                        Raiz do CNPJ
                                    </label>
                                    <span
                                        className="text-roxo_oficial font-bold ml-2"
                                        data-tip="Selecionar Cliente matriz caso seja empresa filial"
                                        data-delay-hide="100"
                                    >
                                        <FaQuestionCircle />
                                    </span>
                                </div>
                                <Select
                                    isClearable
                                    name="parent"
                                    value={values.parent}
                                    className="block uppercase text-blueGray-600 text-xs font-bold"
                                    placeholder="Selecione a Raiz do CNPJ"
                                    onChange={option => {
                                        setFieldValue("parent", option);
                                        if (option) {
                                            setFieldValue("documentNumber", option.documentNumber);
                                            values.documentNumber = option.documentNumber;
                                        }
                                    }}
                                    options={parentOptions}
                                />
                                <ErrorMessage
                                    component="label"
                                    name="parent"
                                    className="text-red font-light w-full"
                                />

                                <label htmlFor="documentNumber" className="text-roxo_oficial font-bold mt-4">
                                    CNPJ
                                </label>
                                <InputMasked
                                    name="documentNumber"
                                    id="documentNumber"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.documentNumber}
                                    mask="99.999.999-9999/99"
                                />
                                <ErrorMessage
                                    component="label"
                                    name="documentNumber"
                                    className="text-red font-light w-full"
                                />

                                <label htmlFor="stateRegistrationNumber" className="text-roxo_oficial font-bold mt-4">
                                    Inscrição Estadual <small>(opcional)</small>
                                </label>
                                <Input
                                    name="stateRegistrationNumber"
                                    id="stateRegistrationNumber"
                                    onChange={handleChange}
                                    value={values.stateRegistrationNumber}
                                    placeholder="Preencha a Inscrição Estadual"
                                />
                                <ErrorMessage component="label" name="stateRegistrationNumber" className="text-red font-light w-full" />

                                {values && values.documentNumber && values.documentNumber.replace(/[\.\-\_\/]+/g, '').length > 8 && <>
                                    <label htmlFor="isParentCompany" className="text-roxo_oficial font-bold mt-4">
                                        É a matriz?
                                    </label>
                                    <Select
                                        isClearable
                                        name="isParentCompany"
                                        value={values.isParentCompany}
                                        className="block uppercase text-blueGray-600 text-xs font-bold"
                                        placeholder="Selecione a Sim se este for o CNPJ da matriz"
                                        onChange={option => {
                                            setFieldValue("isParentCompany", option);
                                        }}
                                        options={[
                                            {
                                                label: 'Sim',
                                                value: true,
                                            },
                                            {
                                                label: 'Não',
                                                value: false,
                                            }
                                        ]}
                                    />
                                    <ErrorMessage
                                        component="label"
                                        name="isParentCompany"
                                        className="text-red font-light w-full"
                                    />
                                </>}

                                <label htmlFor="name" className="text-roxo_oficial font-bold mt-4">
                                    Razão Social
                                </label>
                                <Input
                                    name="name"
                                    id="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    placeholder="Preencha a razão social"
                                />
                                <ErrorMessage component="label" name="name" className="text-red font-light w-full" />

                                <label htmlFor="companyName" className="text-roxo_oficial font-bold mt-4">
                                    Nome fantasia <small>(opcional)</small>
                                </label>
                                <Input
                                    name="companyName"
                                    id="companyName"
                                    onChange={handleChange}
                                    value={values.companyName}
                                    placeholder="Preencha o nome fantasia"
                                />
                                <ErrorMessage component="label" name="companyName" className="text-red font-light w-full" />

                                <label htmlFor="city" className="text-roxo_oficial font-bold mt-4">
                                    Cidade
                                </label>
                                <Input
                                    name="city"
                                    id="city"
                                    onChange={handleChange}
                                    value={values.city}
                                    placeholder="Preencha a cidade"
                                />
                                <ErrorMessage component="label" name="city" className="text-red font-light w-full" />

                                <label htmlFor="codeState" className="text-roxo_oficial font-bold mt-4">
                                    Estado (UF)
                                </label>
                                <Input
                                    name="codeState"
                                    id="codeState"
                                    onChange={handleChange}
                                    value={values.codeState}
                                    placeholder="Preencha o estado"
                                />
                                <ErrorMessage component="label" name="codeState" className="text-red font-light w-full" />

                                <label htmlFor="powerbiUrl" className="text-roxo_oficial font-bold mt-4">
                                    Power BI URL <small>(opcional)</small>
                                </label>
                                <Input name="powerbiUrl" id="powerbiUrl" onChange={handleChange} value={values.powerbiUrl} />
                                <ErrorMessage component="label" name="powerbiUrl" className="text-red font-light w-full" />

                                <label htmlFor="conexosId" className="text-roxo_oficial font-bold mt-4">
                                    Conexos ID <small>(opcional)</small>
                                </label>
                                <Input name="conexosId" id="conexosId" onChange={handleChange} value={values.conexosId} />
                                <ErrorMessage component="label" name="conexosId" className="text-red font-light w-full" />
                            </div>
                            <div className="flex justify-end mt-10">
                                <button
                                    className=" bg-roxo_oficial text-white py-2 px-5 rounded-md"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            Salvando <ClipLoader size={10} loading={isSubmitting} />
                                        </>
                                    ) : (
                                        <>Salvar</>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};
export default ModalRegister;
